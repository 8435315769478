<template>
  <div class="steps">
    <div class="container">
      <div class="steps__counter">
        <p>03/03<br>Privatstunde</p>
      </div>
      <h1>Wer möchte teilnehmen?</h1>

      <div class="form form--participants mx-5 xl:mx-auto">
        <div class="flex flex-col xl:flex-row mb-8 relative" v-for="(item, index) in participants" :key="index">
          <div class="icon">
            <img src="../assets/images/teilnehmer-skikurs.svg" class="teaser">
          </div>
          <div class="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <div class="form-group">
              <label for="lastName">Nachname</label>
              <input id="lastName" v-model="item.last_name" :class="fieldErrorLastName[index] ? 'field__error' : ''" type="text">
              <span class="field__error--label" v-if="fieldErrorLastName[index]">Bitte ausfüllen</span>
            </div>
            <div class="form-group">
              <label for="firstName">Vorname</label>
              <input id="firstName" v-model="item.first_name" :class="fieldErrorFirstName[index] ? 'field__error' : ''" type="text">
              <span class="field__error--label" v-if="fieldErrorFirstName[index]">Bitte ausfüllen</span>
            </div>
            <div class="form-group">
              <label for="birthday">Geburtstag</label>
              <Datepicker id="birthday" :enableTimePicker="false" textInput v-model="item.birthday" autoApply :format="'dd.MM.yyyy'" :maxDate="datepickerUpperLimit" locale="de" cancelText="" selectText="" :class="fieldErrorBirthday[index] ? 'field__error' : ''">
                <template #clear-icon></template>
                <template #input-icon>
<!--                  <img class="input-slot-image" src="../assets/icons/bx-calendar.svg"/>-->
                </template>
              </Datepicker>
              <span class="field__error--label" v-if="fieldErrorBirthday[index]">Bitte ausfüllen</span>
            </div>
            <div class="form-group">
              <label>Kenntnisstand</label>
              <tooltip text="Man beginnt immer mit der Stufe, die bereits beherrscht wird bzw. im Lernpass dokumentiert ist."/>
              <select v-model="item.skill" :class="fieldErrorSkill[index] ? 'field__error' : ''">
                <option value="Einsteiger">Einsteiger</option>
                <option value="Fortgeschrittener">Fortgeschrittener</option>
                <option value="Erfahrener">Erfahrener</option>
                <option value="Experte">Experte</option>
              </select>
              <span class="field__error--label" v-if="fieldErrorSkill[index]">Bitte ausfüllen</span>
            </div>
          </div>
          <div class="action-buttons flex flex-row hover--opacity" v-on:click="removeInputRow(index)" v-if="participants.length > 1">
            <img src="../assets/icons/bx-x.svg">
            <p>entfernen</p>
          </div>
        </div>

        <div class="flex justify-center">
          <div class="btn btn-rectangle hover--opacity" v-on:click="addInputRow()">Teilnehmer hinzufügen</div>
        </div>

        <div class="summary__container ">
          <div class="row flex justify-between items-center">

            <div class="price">
              {{ totalPriceEuro }}
            </div>
            <div class="buttons flex flex-row items-center mt-0">
              <router-link :to="{ name : 'PrivateLessonStep02', params: {editIndex: this.editIndex, discipline: this.discipline}}" class="btn btn-bordered flex justify-center items-center">
                <img src="../assets/icons/bx-right-arrow-alt.svg" class="arrow arrow-left">
              </router-link>
              <div class="btn btn-blue flex justify-center items-center" v-on:click="saveData()">
                <img src="../assets/icons/bx-right-arrow-alt-white.svg" class="arrow arrow-white">
              </div>
            </div>
          </div>
          <div class="row mt-8">
            <p>Preis pro Stunde: 50,00 Euro<br>
              Preis pro Stunde: 50,00 Euro<br>
              Preis pro zusätzlichen Teilnehmer (bis maximal 4. Teilnehmer): 15,00 Euro<br>Preis pro zusätzlichen
              Teilnehmer (ab 5. bis 10. Teilnehmer): 0 Euro</p>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>

import de from "date-fns/locale/de";
import {mapState} from "vuex";
import Tooltip from "../components/Tooltip";
import moment from "moment";

export default {
  name: 'PrivateLessonStep03',
  components: {Tooltip},
  props: {
    discipline: String,
    dates: Array,
    price: String,
    editIndex: String
  },
  data() {
    return {
      de: de,
      participants: [{
        first_name: '',
        last_name: '',
        birthday: null,
        skill: null
      }],

      fieldErrorLastName: [false],
      fieldErrorFirstName: [false],
      fieldErrorBirthday: [false],
      fieldErrorSkill: [false],

      totalPrice: 0,

      datesObj: JSON.parse(this.dates)
    }
  },


  created() {
    if(this.editIndex) {
      const item = this.$store.getters.items[this.editIndex];

      this.participants = item.participants;
      for (let i = 0; i < this.participants.length; i++) {
        if(this.participants[i].birthday != null){

          this.participants[i].birthday = new Date(this.participants[i].birthday);

        }

      }
    }
  },
  computed: {
    totalPriceEuro() {

      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
      return formatter.format(this.totalPrice);
    },
    datepickerLowerLimit() {
      return moment().format('YYYY-MM-DD');
    },
    datepickerUpperLimit() {
      return moment('2023-03-31').format('YYYY-MM-DD');
    }
  },

  methods: {
    editInputs(){
      let hours = '';
      for (let i = 0; i < this.datesObj.length; i++) {
        let fromH =  parseInt(this.datesObj[i].from.split(":")[0])
        let fromM =  parseInt(this.datesObj[i].from.split(":")[1])

        let toH =  parseInt(this.datesObj[i].to.split(":")[0])
        let toM =  parseInt(this.datesObj[i].to.split(":")[1])

        let h = toH - fromH;
        let m = toM - fromM;
        if(m == -30){
          h -= 0.5;
        }
        if(m == 30){
          h+= 0.5
        }
        let time = h;



        if(!time || time == 0){
          hours += 0 + '|'
        } else {
          hours += time + '|'
        }
      }
      let api = process.env.VUE_APP_COURSE_API + '/private_applications_prices/hours/' + hours +'/participant_count/'+ this.participants.length

      this.axios.get(api).then((response) => {
        this.totalPrice = response.data.price
      })
    },
    loopCounter(n) {
      return (n < 10) ? ("0" + n) : n;
    },
    addInputRow() {
      this.participants.push({
        first_name: '',
        last_name: '',
        birthday: null,
        skill: null
      })
    },
    removeInputRow(index) {
      this.participants.splice(index, 1)
    },
    hasError(arr){
      return arr.indexOf(true) > -1
    },
    saveData() {
      for (let i = 0; i < this.participants.length; i++) {
        (this.participants[i].first_name === '' || this.participants[i].first_name == 0) ? this.fieldErrorFirstName[i] = true : this.fieldErrorFirstName[i] = false;
        (this.participants[i].last_name === '' || this.participants[i].last_name == 0) ? this.fieldErrorLastName[i] = true : this.fieldErrorLastName[i] = false;
        (this.participants[i].birthday === '' || this.participants[i].birthday === null )? this.fieldErrorBirthday[i] = true : this.fieldErrorBirthday[i] = false;
        (this.participants[i].skill === '' || this.participants[i].skill === null )? this.fieldErrorSkill[i] = true : this.fieldErrorSkill[i] = false;
      }
      let e1 = this.hasError(this.fieldErrorFirstName)
      let e2 = this.hasError(this.fieldErrorLastName)
      let e3 = this.hasError(this.fieldErrorBirthday)
      let e4 = this.hasError(this.fieldErrorSkill)

      if (!e1 && !e2 && !e3 && !e4) {

        if(this.editIndex) {
          // dispatch information to store
          this.$store.dispatch('editItem', {
            index: this.editIndex,
            item: {
              type: "private_application",
              course_type: this.discipline,
              participants: this.participants,
              dates: this.datesObj,
              price: this.totalPrice
            }
          })
        } else {
          // dispatch information to store
          this.$store.dispatch('saveItem', {
            type: "private_application",
            course_type: this.discipline,
            participants: this.participants,
            dates: this.datesObj,
            price: this.totalPrice
          })
        }

        // route to summary page
        this.$router.push({ name: 'Summary' })

      }
    }
  },
  watch: {
    participants:{
      handler: function(newValue) {
        this.editInputs()
      },
      deep: true
    }
  }
}
</script>
