<template>
  <div class="steps">
    <div class="container">
      <div class="steps__counter">
        <p>02/03<br>Privatstunde</p>
      </div>
      <h1>Wann möchten Sie Ihre Privatstunden nehmen?</h1>

      <div class="form form--times mx-5 xl:mx-auto">
        <div class="flex flex-col xl:flex-row mb-8" v-for="(item, index) in dates" :key="index">
          <div class="counter">{{ loopCounter(index + 1) }}.</div>
          <div class="form-group">
            <label :for="'date-' + index">Datum</label>

            <Datepicker id="birthday" :enableTimePicker="false"
                        textInput v-model="item.date" autoApply :format="'dd.MM.yyyy'"
                        :startDate="datepickerLowerLimit"
                        :disabledDates="disabledDates"
                        :minDate="datepickerLowerLimit" :maxDate="datepickerUpperLimit" locale="de" cancelText=""
                        selectText="" :class="fieldErrorDay[index] ? 'field__error' : ''">
              <template #clear-icon></template>
              <template #input-icon>
                <!--                <img class="input-slot-image" src="../assets/icons/bx-calendar.svg"/>-->
              </template>
            </Datepicker>

            <span class="field__error--label" v-if="fieldErrorDay[index]">Bitte ausfüllen</span>
          </div>
          <div class="form-group">
            <label :for="'begin-' + index">Uhrzeit - Start</label>
            <select :id="'begin-' + index" class="form-select" :class="fieldErrorFrom[index] ? 'field__error' : ''"
                    v-model="item.from">
              <option disabled :selected="!item.from" value="0">Bitte wählen</option>
              <option v-for="option in beginTimes" :value="{name: option.name, number: option.number}"
                      :disabled="validateBeginTime(option.number, index)">
                {{ option.name }} Uhr
              </option>

            </select>
            <span class="field__error--label" v-if="fieldErrorFrom[index]">Bitte ausfüllen</span>
          </div>
          <div class="form-group">
            <label :for="'end-' + index">Uhrzeit - Ende</label>
            <select :id="'end-' + index" class="form-select" :class="fieldErrorTo[index] ? 'field__error' : ''"
                    v-model="item.to">

              <option disabled selected value="0">Bitte wählen</option>
              <option v-for="option in endTimes" :value="{name: option.name, number: option.number}"
                      :disabled="validateEndTime(option.number, index)">
                {{ option.name }} Uhr
              </option>


            </select>
            <span class="field__error--label" v-if="fieldErrorTo[index]">Bitte ausfüllen</span>
          </div>
          <div class="action-buttons flex flex-row hover--opacity" v-on:click="removeInputRow(index)"
               v-if="dates.length > 1">
            <img src="../assets/icons/bx-x.svg">
            <p>entfernen</p>
          </div>
        </div>

        <div class="flex justify-center">
          <div class="btn btn-rectangle hover--opacity" v-on:click="addInputRow()">Zeitraum hinzufügen</div>
        </div>

        <div class="summary__container ">
          <div class="row flex justify-between items-center">

            <div class="price">
              {{ priceToEuro }}
            </div>
            <div class="buttons flex flex-row items-center mt-0">
              <router-link :to="{ name : 'PrivateLessonStep01', params: {editIndex: this.editIndex}}"
                           class="btn btn-bordered flex justify-center items-center mr-5"
                           @click.native="saveDataOnBackButton()">
                <img src="../assets/icons/bx-right-arrow-alt.svg" class="arrow arrow-left">
              </router-link>
              <div class="btn btn-blue flex justify-center items-center" v-on:click="saveData()">
                <img src="../assets/icons/bx-right-arrow-alt-white.svg" class="arrow arrow-white">
              </div>
            </div>
          </div>
          <div class="row mt-8">
            <p>Preis pro Stunde: 50,00 Euro<br>
            Preis pro Stunde: 50,00 Euro<br>
               Preis pro zusätzlichen Teilnehmer (bis maximal 4. Teilnehmer): 15,00 Euro<br>Preis pro zusätzlichen
               Teilnehmer (ab 5. bis 10. Teilnehmer): 0 Euro</p>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import de from "date-fns/locale/de";
import {mapState} from "vuex";
import moment from "moment";


export default {
  name: 'PrivateLessonStep02',
  props: ['discipline', 'editIndex'],
  data() {
    return {
      de: de,
      dates: [{
        date: null,
        from: 0,
        to: 0
      }],

      fieldErrorFrom: [],
      fieldErrorTo: [],
      fieldErrorDay: [],


      beginTimes: [
        {name: '09:00', number: 9},
        {name: '09:30', number: 9.5},
        {name: '10:00', number: 10},
        {name: '10:30', number: 10.5},
        {name: '11:00', number: 11},
        {name: '11:30', number: 11.5},
        {name: '12:00', number: 12},
        {name: '12:30', number: 12.5},
        {name: '13:00', number: 13},
        {name: '13:30', number: 13.5},
        {name: '14:00', number: 14},
        {name: '14:30', number: 14.5},
        {name: '15:00', number: 15},
        {name: '15:30', number: 15.5},
        {name: '16:00', number: 16},
      ],
      endTimes: [
        {name: '10:00', number: 10},
        {name: '10:30', number: 10.5},
        {name: '11:00', number: 11},
        {name: '11:30', number: 11.5},
        {name: '12:00', number: 12},
        {name: '12:30', number: 12.5},
        {name: '13:00', number: 13},
        {name: '13:30', number: 13.5},
        {name: '14:00', number: 14},
        {name: '14:30', number: 14.5},
        {name: '15:00', number: 15},
        {name: '15:30', number: 15.5},
        {name: '16:00', number: 16},
        {name: '16:30', number: 16.5},
        {name: '17:00', number: 17},
      ],
      totalPrice: 0,
      disabledDates: []
    }
  },

  created() {
  const me = this;
    let api = process.env.VUE_APP_COURSE_API + '/private_booking_configs';
    this.axios.get(api).then((response) => {
      response.data.forEach(function (value) {
        if (value.key.key === 'blocked_dates') {
          me.disabledDates = value.value_longtext.split(',').map(item => {
            return moment(item).format('YYYY-MM-DD');
          });

          //disable
          if(moment().hour() >= 18){
            me.disabledDates.push(moment().add(1, 'days').format('YYYY-MM-DD'))
          }
        }
      });
    })

    this.fieldErrorFrom.push(false);
    this.fieldErrorTo.push(false);
    this.fieldErrorDay.push(false);

    if (this.editIndex) {
      const item = this.$store.getters.items[this.editIndex];
      if (item.dates.length > 0) {
        for (let i = 0; i < item.dates.length; i++) {

          let fromObj = this.beginTimes.find(obj => obj.name === item.dates[i].from);
          let toObj = this.endTimes.find(obj => obj.name === item.dates[i].to);

          let date = {
            date: new Date(item.dates[i].date),
            from: fromObj,
            to: toObj
          }

          item.dates[i] = date;
        }
        this.dates = item.dates;
      }

    }
  },
  computed: {

    priceToEuro() {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
      return formatter.format(this.totalPrice);
    },
    datepickerLowerLimit() {
      let now = moment();
      let lowerLimit = moment().add(1, 'days');

      if (now.hour() >= 18) {
        lowerLimit.add(1, 'days');
      }
      return lowerLimit.format('YYYY-MM-DD');
    },
    datepickerUpperLimit() {
      return moment('2023-03-31').format('YYYY-MM-DD');
    },
    disabledDates() {
      return this.disabledDates;
    }

  },
  methods: {
    editInputs() {
      let hours = '';
      for (let i = 0; i < this.dates.length; i++) {
        let time = this.dates[i].to.number - this.dates[i].from.number;
        if (!time || time === 0) {
          hours += 0 + '|'
        } else {
          hours += time + '|'
        }
      }

      let participants = 1;
      const item = this.$store.getters.items[this.editIndex];
      if (item.participants && item.participants.length > 0) {
        participants = item.participants.length;
      }
      let api = process.env.VUE_APP_COURSE_API + '/private_applications_prices/hours/' + hours + '/participant_count/' + participants
      this.axios.get(api).then((response) => {
        this.totalPrice = response.data.price
      })
    },
    loopCounter(n) {
      return (n < 10) ? ("0" + n) : n;
    },
    addInputRow() {
      this.dates.push({
        date: null,
        from: 0,
        to: 0
      })
    },
    validateBeginTime(number, index) {
      if (this.dates[index].to === 0) {
        return false
      }
      let to = this.dates[index].to.number;

      return to - 1 < number;
    },
    validateEndTime(number, index) {
      let from = this.dates[index].from.number;
      return from + 1 > number;
    },
    removeInputRow(index) {
      this.dates.splice(index, 1)
    },
    hasError(arr) {
      return arr.indexOf(true) > -1
    },
    saveDataOnBackButton() {
      if (this.dates.length > 0) {
        for (let i = 0; i < this.dates.length; i++) {
          if (this.dates[i].from && this.dates[i].to) {
            let f = this.dates[i].from.name;
            let t = this.dates[i].to.name;
            this.dates[i].from = f;
            this.dates[i].to = t;
          }
        }
      }

    },
    saveData() {
      for (let i = 0; i < this.dates.length; i++) {
        (this.dates[i].from === '' || this.dates[i].from === 0) ? this.fieldErrorFrom[i] = true : this.fieldErrorFrom[i] = false;
        (this.dates[i].to === '' || this.dates[i].to === 0) ? this.fieldErrorTo[i] = true : this.fieldErrorTo[i] = false;
        (this.dates[i].date === '' || this.dates[i].date === null || this.dates[i].date < this.datepickerLowerLimit || this.dates[i].date > this.datepickerUpperLimit) ? this.fieldErrorDay[i] = true : this.fieldErrorDay[i] = false;
      }
      let e1 = this.hasError(this.fieldErrorDay)
      let e2 = this.hasError(this.fieldErrorTo)
      let e3 = this.hasError(this.fieldErrorFrom)

      let price = this.totalPrice;

      if (!e1 && !e2 && !e3) {

        for (let i = 0; i < this.dates.length; i++) {
          if (this.dates[i].from && this.dates[i].to) {
            let f = this.dates[i].from.name;
            let t = this.dates[i].to.name;
            this.dates[i].from = f;
            this.dates[i].to = t;
          }
        }

        let itemToSave = this.$store.getters.items[this.editIndex];

        this.$store.dispatch('editItem', {
          index: this.editIndex,
          item: {
            type: "private_application",
            course_type: itemToSave.course_type,
            participants: itemToSave.participants,
            dates: this.dates,
            price: price
          }
        })

        this.$router.push({
          name: 'PrivateLessonStep03',
          params: {
            dates: JSON.stringify(this.dates),
            discipline: this.discipline,
            price: price,
            editIndex: this.editIndex
          }
        })
      }
    }
  },
  watch: {
    dates: {
      handler: function (newValue) {
        this.editInputs()
      },
      deep: true
    }
  }

}
</script>
